import type { Config } from '@bugsnag/js'
import Bugsnag from '@bugsnag/js'
import { currentUser } from '@/src/lib/railsUtils'

// NOTE: テスト実行時の notifyReleaseStages 警告が邪魔だからログ無効化
const loggerOptions = (): Pick<Config, 'logger'> => {
  // eslint-disable-next-line unicorn/no-null
  return process.env.RAILS_ENV === 'test' ? { logger: null } : {}
}

const userOptions = (): Pick<Config, 'user'> => {
  const user = currentUser()
  if (!user) return {}

  return {
    user: {
      id: user.id,
      email: user.email,
    },
  }
}

window.bugsnagClient = Bugsnag.start({
  apiKey: '002beac8ef580318cb2d33bf468717b1',
  releaseStage: process.env.RAILS_ENV,
  enabledReleaseStages: ['staging', 'production'],
  ...loggerOptions(),
  ...userOptions(),
})
